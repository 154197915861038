import Layout from "../layouts/layout"
import * as React from "react"

export default class Index extends React.Component {
    props: {
        data: {
            site: {
                siteMetadata: {
                    siteTitle: string
                }
            }
            allMdx: any;
            allImageSharp: any;
            allFile: any;
        }
    }
    constructor(props) {
        super(props)
    }

    public render() {

        return (
            <Layout>
                <main className={"container"}>
                    <p>404 Not Found</p>

                </main>
            </Layout>
        )
    }
}